<template>
  <div>
    <v-card>
      <v-card-title>
        Aktivitas : {{ $route.params.act_name }}
      </v-card-title>
      <v-card-text>
        <v-card style="border-radius: 10px 10px 0 0; border: .8px solid #1F2D3D;">
          <v-card-title class="py-2" style="background: #F1F5F9;border-radius: 10px 10px 0 0; font-size: 16px; ">
            Diskusi Publik
          </v-card-title>
          <v-card-text style="height: 500px;overflow: auto;">
            <div class="mt-2">Berikan Diskusi</div>
            <div class="d-flex mb-2" style="position: relative;">
              <v-textarea
                style="font-size: 12px"
                placeholder="Tuliskan pesan di sini"
                v-model="discussion_content"
                rows="3"
                outlined
                auto-grow>
              </v-textarea>
              <v-btn 
                x-small
                class="my-2"
                style="z-index: 2;position: absolute; right: 1%; bottom: 0;"
                color="primary"
                :loading="isLoading"
                @click="sendReplies({ id: '', program_id: $route.params.program, topic_id: $route.params.topic, activity_id: $route.params.id, text: discussion_content  })">
                Kirim
              </v-btn>
            </div>

            <Discussion v-for="(chat, cidx) in chats" :key="cidx" :child="chat" @send-reply="sendReplies"
              @fetchData="fetchDiscussion" />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import moment from "moment";
import Discussion from "./Discussion.vue";

export default {
  data() {
    return {
      isLoading: false,
      showReplies: false,
      showRepliesChild: false,
      discussion_content: "",
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },
      chats: [],
    };
  },
  created() {
    this.$emit("page-changed", 2, {
      title: 'Data Diskusi Publik',
      link: this.$route.path,
    });
  },
  computed: {},
  mounted() {
    this.fetchDiscussion(null);
  },
  methods: {
    async fetchDiscussion() {
      this.isLoading = true;
      let param = {
        // parent_id: parent == null ? "0" : parent,
        program_id: this.$route.params.program,
        topic_id: this.$route.params.topic,
        activity_id: this.$route.params.id,
        sort: 'created_at',
        sort_type: 'asc',
        limit: -1
      };
      await this.axios.get(`/discussion/v1/list`, {
        params: param
      }).then((response) => {
        let res = response.data;
        res.data.list.forEach(async (el) => {
          el.showForm = false;
          el.showReplies = false;
          el.text = "";
          el.children = null;
        });
        this.chats = this.list_to_tree(res.data.list);
        // console.log(this.chats);
        this.isLoading = false;
      });
    },
    list_to_tree(list) {
      var map = {}, node, roots = [], i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== "") {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node);
        }
        else {
          roots.push(node);
        }
      }
      return roots;
    },
    convertTime(time) {
      return moment(time).format('HH:mm A');
    },
    deleteDiscussion(item, index, itemchild, indexchild, type) {
      // console.log(item, index, itemchild, type);
      if (type == 'parent') {
        if (item.replies && item.replies != null && item.replies.length > 0)
          return alert('Terdapat diskusi lain di dalam diskusi ini.');
        this.chats.splice(index, 1);
      }
      else {
        this.chats[index].replies.splice(indexchild, 1);
      }
    },
    sendReplies(item) {
      let data = {
        parent_id: item.id == "" ? null : item.id.toString(),
        program_id: item.program_id,
        topic_id: item.topic_id,
        activity_id: item.activity_id,
        content: item.text
      };
      this.axios.post(`/discussion/v1/create`, data).then(() => {
        // let res = response.data;
        // console.log(res);
        this.discussion_content = ""
        this.fetchDiscussion();
      }).catch((error) => {

        if(error.response.data.message == 'error validation') {
          let errors = "";
          Object.keys(error.response.data.validation).forEach((resp) => {
            errors += error.response.data.validation[resp] + "<br>";
          })
          this.snackbar = {
            state: true,
            text: errors,
            color: "error",
            timeout: 4000
          }
        }else {
          this.snackbar = {
            state: true,
            text: error.response.data.message,
            color: "error",
            timeout: 4000
          }
        }
      })
    }
  },
  components: { Discussion }
}
</script>