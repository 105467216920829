<template>
  <div>
    <v-list-item class="py-0 mb-3 ml-0 px-0" two-line>
      <v-list-item-content class="py-0">
        <v-list-item-title>
          <div class="font-weight-bold" style="font-size:13px;color: #0D082C;">{{ child.author_name }}</div>
        </v-list-item-title>
        <v-list-item-subtitle style="font-size: 11px;color: #0D082C; max-width: 40rem; line-height: 18px;"
          class="text-wrap mb-1">
          <!-- <span class="font-weight-medium" style="color: blue">@{{ child.author_name }}</span>  -->
          {{ child.content }}
        </v-list-item-subtitle>
        <v-list-item-subtitle style="font-size: 11px; gap: 7px" class="d-flex">
          {{ convertTime(child.created_at) }}
          <div class="primary--text font-weight-medium" style="cursor: pointer;"
            @click="child.showForm = !child.showForm">{{ child.showForm ? 'Batal' : 'Balas' }}</div>
          <!-- <div class="font-weight-medium" style="color: #CD223A;cursor: pointer;" @click="deleteDiscussion(chat)">Hapus</div> -->
        </v-list-item-subtitle>
        <v-expand-transition>
          <div v-if="child.showForm" class="d-flex justify-space-between align-end mt-2" style="position: relative;">
            <v-textarea style="font-size: 12px" placeholder="Tuliskan pesan di sini" v-model="child.text" rows="3"
              outlined auto-grow>
            </v-textarea>
            <v-btn 
              x-small 
              class="my-2"
              style="z-index: 1;position: absolute; right: 1%; bottom: 0;" 
              color="primary" 
              :loading="isLoading"
              @click="replyDiscuss(child)">
              Kirim
            </v-btn>
          </div>
        </v-expand-transition>
      </v-list-item-content>
    </v-list-item>


    <v-expand-transition>
      <div v-show="child.showReplies">
        <Discussion class="ml-6" v-for="(chat, cidx) in child.children" :key="cidx" :child="chat"
          @send-reply="sendReplies" @fetchData="$emit('fetchData')" />
      </div>
    </v-expand-transition>

    <v-btn text x-small color="primary" class="my-0 py-0" style="font-size: 10px;"
      v-if="child.total_child && child.total_child != null && child.total_child > 0"
      @click="child.showReplies = !child.showReplies">
      <v-icon left>{{ child.showReplies ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill' }}</v-icon>
      {{ child.showReplies ? `Tutup Balasan` : `Lihat ${child.total_child} Balasan` }}
    </v-btn>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Discussion',
  props: {
    child: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },
    }
  },
  methods: {
    convertTime(time) {
      return moment(time).format('DD MMM YY HH:mm A');
    },
    replyDiscuss(item) {
      this.$emit('send-reply', item)
      this.$emit('fetchData')
      setTimeout(() => {
        item.showForm = false
        this.isLoading = false
      }, 20);
    },
    sendReplies(item) {
      let data = {
        parent_id: item.id == "" ? null : item.id.toString(),
        program_id: item.program_id,
        topic_id: item.topic_id,
        activity_id: item.activity_id,
        content: item.text
      };
      this.axios.post(`/discussion/v1/create`, data).then(() => {
        // let res = response.data;
        this.$emit('fetchData')
      }).catch((error) => {
        
        if(error.response.data.message == 'error validation') {
          let errors = "";
          Object.keys(error.response.data.validation).forEach((resp) => {
            errors += error.response.data.validation[resp] + "<br>";
          })
          this.snackbar = {
            state: true,
            text: errors,
            color: "error",
            timeout: 4000
          }
        }else {
          this.snackbar = {
            state: true,
            text: error.response.data.message,
            color: "error",
            timeout: 4000
          }
        }

      });
    }
  }
}
</script>