<template>
  <div>

    <div class="d-flex justify-start align-start" style=" gap: 10px">
      <div style="flex: 1" id="dt_program">
        <!-- <v-data-table
          v-if="!showDetails"
          :loading="isLoading"
          class="transparent"
          :headers="headers"
          :item-key="'id'"
          :items="data.list"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          :mobile-breakpoint="0"
          loading-text="Loading... Please wait!"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="{
            'items-per-page-text':'Row per page : '
          }"
          :server-items-length="data.total"
        >
          <template slot="item" slot-scope="{ item }">
            <tr class="flat-b-1 rounded-lg" style="cursor: pointer;" @click="onShowDetails(item)">
              <td class="white rounded-l-lg">{{ item.username }}</td>
              <td class="white">{{ item.learning_targets }}</td>
              <td class="white text-wrap">{{ item.discussion.substring(0, 50) + '. . .' }}</td>
              <td class="white rounded-r-lg">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="onShowDetails(item)"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      ri-arrow-right-line
                    </v-icon>
                  </template>
                  <span>Lihat Detail Diskusi</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table> -->
        
        <div v-if="sortedRooms.length > 0">
          <v-card v-for="(item, ind) in sortedRooms" :key="ind">
            <v-list-item two-line class="black--text" @click="selectRoomHandler(item)">
              <v-badge
              bordered
              bottom
              :color="item.online ? 'green' : 'red'"
              dot
              offset-x="10"
              offset-y="10"
              class="mr-2" >
              <v-avatar size="38">
                <!-- <v-icon v-if="!room.isPrivate" dark>mdi-account-circle</v-icon> -->
                  <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${item.member.name[0].toUpperCase()}`" :alt="`${item.member.name}`" />
              </v-avatar>
              </v-badge>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium text-capitalize" style="font-size: 16px;">{{item.member.name}} </v-list-item-title>
                <v-list-item-subtitle class="blue-grey--text text--lighten-1 font-weight-medium" style="font-size: 13px;">
                  {{ item.member.email }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle class="text-wrap" style="font-size: 11px;">
                  {{ item.discussion.substring(0, 50) + '. . .'  }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn 
                  color="primary" 
                  class="font-weight-bold"
                  depressed
                  x-small>
                  <v-icon left>ri-chat-3-line</v-icon>
                  Mulai
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>
        </div>
        <div v-else class="d-flex-row justify-center align-center text-center">
          <v-img :src="require('@/assets/images/data_empty.png')" width="350" class="mx-auto"></v-img>
          <div class="text-h6 mt-7">Diskusi Private Anda masih kosong</div>
          <div class="body-2">Belum terdapat siswa atau peserta yang memulai untuk diskusi secara private dengan Anda</div>
        </div>
      </div>
      <div>
        <v-expand-x-transition>
          <v-card width="700" v-show="showDetails">
            <v-card-title class="py-0">
              Detail Diskusi
              <v-spacer></v-spacer>
              <v-btn icon @click="showDetails = false">
                <v-icon>ri-close-circle-line</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow: auto; height: 500px" class="chat-container">

              <v-card>
                <v-card-text>
                  <div v-if="chats && chats.length > 0">
                    <div 
                      v-for="(chat, i) in chats" :key="i">
                      <div class="d-flex justify-start mb-4" v-if="chat.type == 1">
                        <div class="img_cont_msg">
                            <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${chat.name[0].toUpperCase()}`" :alt="`${chat.name}`" class="rounded-circle user_img_msg"/>
                        </div>
                        <div class="msg_cotainer">
                          <div style="position: static;" class="font-weight-bold text-capitalize">{{ chat.name }}
                            <span style="font-size: 11px;">[{{ chat.license }}]</span>
                            <span class="font-weight-light ml-2" style="font-size: 10px;">{{ convertDate(chat.created_at) }}</span>
                          </div>
                          <div style="background: #bdc3c760 !important;" class="px-2 py-1 rounded-lg">
                            <div>
                              <div class="font-weight-black" style="font-size: 9px; ">Topik:</div>
                              <div class="font-weight-bold" style="font-size: 8px; line-height: 1em; color: #636e72">{{ chat.topic.name }}</div>
                            </div>
                            <div>
                              <div class="font-weight-black" style="font-size: 9px;">Aktifitas:</div>
                              <div class="font-weight-bold pb-2" style="font-size: 8px;line-height: 1em; color: #636e72">{{ chat.activity.name }}</div>
                            </div>
                          </div>
                          <div class="mb-3" style="font-size: 13px;" v-html="chat.message"></div>
                        </div>
                      </div>
                      <div class="d-flex justify-end mb-4" v-else>
                        <div class="msg_cotainer_send">
                          <div style="position: static;" class="font-weight-bold text-capitalize">{{ chat.name }} <span class="font-weight-light ml-2" style="font-size: 10px;">{{ convertDate(chat.created_at) }}</span></div>
                          <!-- <div style="position: static;" class="font-weight-bold text-uppercase">{{ chat.name }}</div> -->
                          <div class="mb-3" style="font-size: 13px;" v-html="chat.message"></div>
                          <!-- <div  class="font-weight-bold text-right" style="font-size: 10px; position: absolute; bottom: 0; right: 10px;">{{ convertDate(chat.created_at) }}</div> -->
                        </div>
                        <div class="img_cont_msg">
                            <img :src="`https://placehold.co/600x600/34495e/ecf0f1?text=${chat.name[0].toUpperCase()}`" :alt="`${chat.name}`" class="rounded-circle user_img_msg"/>
                            <!-- <img src="https://avatars.hsoubcdn.com/ed57f9e6329993084a436b89498b6088?s=256" class="rounded-circle user_img_msg"> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" v-else>
                    <div class="text-h6">Chat masih kosong</div>
                    <div>Anda bisa memulai chat terlebih dahulu atau menunggu chat dari member</div>
                  </div>
                </v-card-text>
              </v-card>

            </v-card-text>
            <v-card-actions>
              <v-textarea
                placeholder="Ketik pesan disini, tekan enter untuk mengirim"
                v-model.trim="message"
                rows="1"
                v-on:keyup.enter.exact="sendMessageChat"
                auto-grow>
                <!-- :rows="message.length > 50 ? message.length/50 : 1" -->
              </v-textarea>
              <v-btn
                @click="sendMessageChat"
                class="ml-5"
                color="primary">
                Kirim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-x-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex' 
import moment from 'moment';
import firebase from '@/firebase'
export default {
  name: "program-table",
  // props: {
  //   data: { required: true, type: Object, default: () => ({}) },
  //   loading: { type: Boolean, default: () => false },
  // },
  data: () => ({
    showDetails: false,
    sortBy: "total",
    sortDesc: false,
    headers: [
      { text: "Nama Program", class: "text-no-wrap", sortable: false, },
      { text: "Bidang Studi", class: "text-no-wrap", sortable: false, },
      { text: "Pesan Belum Terbaca", value:"total",class: "text-no-wrap", sortable: false, },
      { text: "Aksi", class: "text-no-wrap", sortable: false, },
    ],
    table_loading: false,
    details: {},
    data: {
      list: [
        {
          username: "Akbar Syarifudin",
          learning_targets: "Kuliner",
          discussion: "Sagittis montes quisque taciti cras volutpat lacinia mi risus felis sodales mus enim ultricies phasellus vestibulum aptent in bibendum laoreet ultrices luctus",
          chats: [
            {
              type: "user",
              username: "Akbar Syarifudin",
              disscuss: "Lorem ipsum dolor ",
              created_at: new Date()
            },
            {
              type: "trainer",
              username: "Rahmat Yulianto",
              disscuss: "Eget dictum urna dui arcu gravida senectus",
              created_at: new Date()
            },
            {
              type: "user",
              username: "Akbar Syarifudin",
              disscuss: "Sagittis montes quisque taciti cras volutpat lacinia mi risus felis sodales mus enim ultricies phasellus vestibulum aptent in bibendum laoreet ultrices luctus",
              created_at: new Date()
            },
            {
              type: "trainer",
              username: "Rahmat Yulianto",
              disscuss: "Sagittis montes quisque taciti cras volutpat lacinia mi risus felis sodales mus enim ultricies phasellus vestibulum aptent in bibendum laoreet ultrices luctus",
              created_at: new Date()
            },
            {
              type: "trainer",
              username: "Rahmat Yulianto",
              disscuss: "Sagittis montes quisque taciti cras volutpat lacinia mi risus felis sodales mus enim ultricies phasellus vestibulum aptent in bibendum laoreet ultrices luctus",
              created_at: new Date()
            },
          ]
        },
        {
          username: "Rizky Aditya",
          learning_targets: "Programming",
          discussion: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, odit.",
        },
        {
          username: "Rosmania Larasati",
          learning_targets: "Keseharian",
          discussion: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, odit.",
        },
        {
          username: "Tengku Hermansyah",
          learning_targets: "Layanan Masyarakat",
          discussion: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, odit.",
        },
        {
          username: "Laras Simanjuntak",
          learning_targets: "keahlian",
          discussion: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, odit.",
        },
      ]
    },
    message: "",
    roomId: "",
    memberID: "",
    rooms: [],
    chats: [],
    onlineUsers: [],
    onlineUserIDs: [],
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
    statusColor() {
      return this.online ? "green" : "grey";
    },
    program() {
      return {
        title: "Data Diskusi Private",
      };
    },
    ...mapState('roomModule', {
      roomID: state => state.activeRoom
    }),
    sortedRooms() {
      var activeRooms = [];
      var inactiveRooms = [];
      var rooms = this.rooms;

      for (var i = 0; i < rooms.length; i++) {
        var room = rooms[i];
        // Preprocessing: Build the room name and photo
        // var names = [];
        // var trainers = this.trainers.filter(val => {
        //   // Remove self and filter contacts that are in the room
        //   return this.userState.ID != val.id && room.users.indexOf(val.id) >= 0;
        // });

        // check data online users 
        if( this.onlineUserIDs.indexOf(room.member.id) >= 0) {
          room.online = true
        }else {
          room.online = false
        }

        // for (var j = 0; j < trainers.length; j++) {
        //   names.push(trainers[j].name);
        // }
        // room.name = names.join(', ');

        // if (room.isPrivate) {
        //   room.photoUrl = trainers[0] ? trainers[0].photoUrl : "";
        // }

        // For active room
        if (room.id == this.roomID) {
          room.active = true;
          activeRooms.push(room);
        } else {
          // For inactive room
          room.active = false;
          inactiveRooms.push(room);
        }

        // console.log(room);
      }

      return [...activeRooms, ...inactiveRooms];
    },
  },
  created() {
    // this.$emit("page-changed", 1, {
    //   title: 'Topik',
    //   link: this.$route.path,
    // });
    this.$emit("page-changed", 1, {
      title: this.program.title,
      link: this.$route.path,
    });

    this.isLoaded  = true
    firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ROOM)
      .where("program_id", "==", this.$route.params.id)
      .where("trainer.id", "==", this.$store.state.auth.user.id)
      .onSnapshot((snapshot) => {
        const rooms = [];
        snapshot.forEach((doc) => {
          const room = doc.data();
          room.id = doc.id
          rooms.push(room);
        });

        this.rooms = rooms;
        this.isLoaded = true;
      });

    // GET ONLINE USER
    let datetime = new Date();
    datetime.setHours(datetime.getHours() - 1);
    firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ONLINE)
    .where('login_at', '>=', datetime)
    .onSnapshot((snapshot) => {
      const users = []
      const userIDs = []
      snapshot.forEach((doc) => {
        const onlineTrainer = doc.data();
        if(onlineTrainer.type == 1) {
          // console.log(onlineTrainer);
          users.push(onlineTrainer)
          userIDs.push(onlineTrainer.id)
        }
      });

      this.onlineUsers = users
      this.onlineUserIDs = userIDs
    });
  },
  methods: {

    ...mapActions('roomModule', ['selectRoom', 'clearRoom']),
    selectRoomHandler(room) {
      this.memberID = room.member.id
      if (this.roomID != room.id) {
        // Clear the room selection first
        this.clearRoom();

        // Select the room again
        let data = {
          room: room.id,
          targetUser: null,
          currentUser: null
        };

        this.selectRoom(data);
        setTimeout(() => {
          this.roomId = room.id
          this.showDetails = true
          this.getMessageList()
        }, 200);
      }else {
        
        // Select the same room again
        let data = {
          room: room.id,
          targetUser: room.member_id,
          currentUser: room.trainer_id
        };
        this.selectRoom(data);

        setTimeout(() => {
          this.roomId = room.id
          this.showDetails = true
          this.getMessageList()
        }, 200);
      }
      
      
      // this.$emit('click:selectedRoom')
    },
    getMessageList() {
      if (this.roomId) {
        this.chats = []
        firebase.firestore().collection(process.env.VUE_APP_FIREBASE_COLLECTION_ROOM)
        .doc(this.roomId).collection(process.env.VUE_APP_FIREBASE_COLLECTION_MESSAGE)
        .orderBy("created_at", "asc")
          .onSnapshot((snapshot) => {
            const newChats = [];
            snapshot.docChanges().forEach(function (change) {
              // console.log('chat',change.doc.data());
              if (change.type === "added") {
                var chat = {
                  id: change.doc.id,
                  ...change.doc.data(),
                };

                newChats.push(chat);
              }
            });

            this.chats = this.chats.concat(newChats);
            this.isLoaded = true;
          });

        this.showDetails = true
      }
    },
    async sendMessageChat() {
      
      setTimeout(() => {
        this.message = ""
      }, 50);
      
      const resp = await this.axios.post(`/discussion/v1/discussion-private/room/${this.roomID}/send-message`, { message: this.message, topic: null, activity: null, license: null, reference_id: this.memberID })
      // roomService.sendMessage(sender, room, message);
      const resResp = resp.data
      

      return resResp
    },
    convertDate(time) {
      return moment(time.toDate()).format('DD MMM YYYY HH:mm')
    },
    onShowDetails(item) {
      this.details = item
      this.showDetails = true
    },
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      // console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style>
  .user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
  }
  .msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    font-size: 12px;
    max-width: 400px;
    border-radius: 0 10px 0 10px;
    background-color: #E5E9F2;
    padding: 10px;
    position: relative;
  }

  .msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 12px;
    max-width: 400px;
    border-radius: 10px 0 10px 0;
    background-color: #FDF7B2;
    padding: 10px;
    position: relative;
  }

  #dt-program .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 10px !important;
  }
  #dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }
  .theme--light #dt-program .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
    border:0px solid!important;
  }
  .theme--light #dt-program .v-data-table .v-data-footer {
    border:0px solid!important;    
  }
  #dt-program .v-data-footer__select {
    justify-content: left !important;
    position: absolute;
  }
  .v-text-field--outlined fieldset{
    border:0px solid;
  }
  .v-application--is-ltr #dt-program .v-data-footer__select .v-select {
    margin: 13px 0 13px 0px;
  }
</style>